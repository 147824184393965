import { getCurrentDateAndTimeinAuFormat } from './dateUtils';

class Logger {
    constructor() {
        this.logs = { success: [], information: [], errors: [] };
    }

    addSuccess(message) {
        this.logs.success.push(`[${getCurrentDateAndTimeinAuFormat()}] ${message}`);
    }

    addInformation(message) {
        this.logs.information.push(`[${getCurrentDateAndTimeinAuFormat()}] ${message}`);
    }

    addError(message) {
        this.logs.errors.push(`[${getCurrentDateAndTimeinAuFormat()}] ${message}`);
    }

    getLogs() {
        return { ...this.logs };
    }

    clearLogs() {
        this.logs = { success: [], errors: [] };
    }
}

// Singleton instance
const logger = new Logger();
export default logger;