import React, { useState } from 'react';
import Papa from 'papaparse';
import '../../assets/css/form.css';
import CsvUploaderRepository from '../../domain/repositories/CsvUploaderRepository';
import { API_BASE_URL } from "../../utils/constants/api_constants";
import  logger  from '../../utils/Logger';


const CsvUploader = () => {
    const [logs, setLogs] = useState({ success: [], information: [], errors: [] });

    const csvUploaderRepository = new CsvUploaderRepository();

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (file) {
            setLoading(true);
            setError(null);
            setSuccess(false);

            try {
                const data = await parseCsvFile(file);
                console.log("Csv data is: ", data)
                await uploadDataToApi(data);
                setSuccess(true);
            } catch (err) {
                setLogs((prev) => ({
                    ...prev,
                    errors: [...prev.errors, `Failed to upload CSV: ${err}`],
                }));

                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        else {
            setError('Please choose a file first');
        }
    };

    const parseCsvFile = (file) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    if (results.errors.length > 0) {
                        setLogs((prev) => ({
                            ...prev,
                            errors: [...prev.errors, `Failed to parse CSV at complete: ${results.errors}`],
                        }));
                        reject(new Error('Error parsing CSV file'));
                    } else {
                        resolve(results.data);
                    }
                },
                error: (err) => {
                    setLogs((prev) => ({
                        ...prev,
                        errors: [...prev.errors, `Failed to parse CSV: ${err}`],
                    }));
                    reject(err);
                },
            });
        });
    };

    const uploadDataToApi = async (data) => {

        try {

            const uploadToApiResponse = await csvUploaderRepository.uploadCSVDataToApi(data);
        } catch (error) {
            logger.addError(`Error uploading csv ${error}`);
            console.error(`Error uploading data to API ${error}`)
            throw new Error(`Error uploading data to API`);
        }
    };

    return (

        <div>
            <div>
                <h5 style={{ textAlign: 'left', margin: '0' }}>API: {API_BASE_URL}</h5>
            </div>

            <h2>Select a CSV file to upload</h2>

            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <input type="file" id="file-input" onChange={handleFileChange} />
                </div>
                <div className="form-group">

                    <button type="submit" disabled={loading}>Upload</button>
                </div>
            </form>
            {loading && <p>Uploading. Please wait...</p>}
            {error && <p className="error-message">Error: {error}</p>}
            {success && <p className="success-message">Upload successful!</p>}
        </div>
    );
};

export default CsvUploader;
